.portfolioCard {
    margin: 1px;
}

/* .tabletop {
    padding-left: 20%;
} */

.aboutBack {
    background: radial-gradient(circle, #003546, #fce4e4);;
    /* padding-top: 2px; */
}
.text{
    color: #f7e290;
  }

.tourWords{
    text-align: center;
}
.tourTitle{
    color: #f0f2f2;
    text-align: center;
    font-size: 10vw;
    font-family: 'Carattere', bold;
}

.menuContent{
    color: #f0f2f2;
    font-size: 5vw;
    text-align: center;
    font-family: 'Carattere', bold;
}
.boxer{
  
    text-align: center;
    }
  