.portfolioCard {
    margin: 15px;
}

.tabletop {
    padding-left: 20%;
}

.aboutBack {
    background: radial-gradient(circle, #003546, #fce4e4);
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .text{
    color: #00050d;
    font-family: 'Carattere', bold;
    font-weight: 600;
    font-size: 75px;
  }
  .text2{
    color: #f0f2f2;
    text-align: center;
    font-family: 'Carattere', bold;
    font-weight: 600;
    font-size: 75px;
  }

  .cakes{
    text-align: center;
    font-family: 'Carattere', bold;
  font-size: 75px;
  font-weight: 600;
}

.cupcakes{
  text-align: center;
  font-family: 'Carattere', bold;
  font-size: 75px;
  font-weight: 600;
}
  .fontSize{
    font-size:40px;
    font-weight: 600;
  }
  .boxer{
  
  text-align: center;
  }

  .text3{
    color: #f0f2f2;
    text-align: center;
    font-family: 'Times New Roman', bold;
    font-weight: 600;
    font-size: 75px;
  }