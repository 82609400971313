.portfolioCard {
    margin: 15px;
}

.tabletop {
    padding-left: 20%;
}

.aboutBack {
    background: radial-gradient(circle, #003546, #fce4e4);
    
}