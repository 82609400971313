nav.navbar.navbar-default {
  margin-bottom: 0;
  
}

.navibar {
  
}

.navitext{
  /* background: seashell; */
  background: radial-gradient(circle, #b0c9af, #3e8c53, #05b334);
}

.text{
  color: #f0f2f2 !important;
  font-family: 'Carattere';
  font-weight: 600;
  font-size: 30px;
}